import React from 'react';
import './App.css';
import { CssBaseline } from '@material-ui/core';
import { Main } from './main/Main';

const App = () => (
  <div className="App">
    <CssBaseline />
    <div style={{ backgroundColor: '#9082DB', height: '100vh', maxWidth: '1600px', margin: 'auto', boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }}>
      <Main />
    </div>
  </div>
);

export default App;
