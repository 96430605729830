import React from 'react';
import { Layout } from '../layout/Layout';
import { Home } from '../home/home';
import { Frame } from '../layout/Frame';
import { ContactForm } from '../contact-form/ContactForm';
import { VideoFrame } from '../video/Video';
import { Trainings } from '../trainings/Trainings';
import { Contact } from '../contact/Contact';
import { useIsSmallerThen } from '../hooks/utls';
import { Copyright } from '../copyright/Copyright';
import { Reviews } from '../reviews/Reviews';

export const Main = () => {
  const isSmallView = useIsSmallerThen('md');
  return (
    <>
      <Layout>
        <Contact />
        {/*<Copyright />*/}
      </Layout>
    </>
  );
};
