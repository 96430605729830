import React, { ReactNode } from 'react';
import {
  createMuiTheme, createStyles, makeStyles, ThemeProvider,
} from '@material-ui/core/styles';
import {
  AppBar, Avatar, CssBaseline, Theme, Toolbar,
} from '@material-ui/core';
import CookieConsent from 'react-cookie-consent';
import { useIsSmallerThen } from '../hooks/utls';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Rogan',
  },
  palette: {
    primary: {
      main: '#9164ab',
    },
    secondary: {
      main: '#6667ab',
    },
  },
});

const useStyles = makeStyles((th: Theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: th.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginRight: th.spacing(2),
    textAlign: 'start',
    [th.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  large: {
    width: th.spacing(10),
    height: th.spacing(7),
    borderRadius: '0%',
  },
  sectionDesktop: {
    [th.breakpoints.up('sm')]: {
      display: 'flex',
    },
    [th.breakpoints.down('sm')]: {
      padding: 1,
      marginRight: theme.spacing(1),
    },
  },
  sectionMobile: {
    display: 'flex',
    [th.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const handleGoToClick = (id: string, position: 'center' | 'start' = 'center') => (event: React.MouseEvent<HTMLElement>) => {
  const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
    `#${id}`,
  );

  if (anchor) {
    anchor.scrollIntoView({ behavior: 'smooth', block: position });
  }
};

export function Layout({ children }: { children: ReactNode }) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div style={{paddingTop: 10}}>
          {children}
        </div>
      </ThemeProvider>
      <CookieConsent buttonText="Zgoda">
        Ta strona wykorzystuje pliki cookie aby dostarczyć tobie jak najlepsze
        doświadczenia. Jeśli chcesz z nich zrezygnować dostosuj ustawienia swojej przeglądarki.
      </CookieConsent>
    </>
  );
}
