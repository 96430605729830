import {
  createStyles, makeStyles,
} from '@material-ui/core/styles';
import {Grid, Paper, Typography} from '@material-ui/core';
import React from 'react';
import { SocialIcon } from 'react-social-icons';

const useStyles = makeStyles((th) => createStyles({
  container: {
    textAlign: 'justify',
    padding: '2vh',
    marginTop: '5vh',
    [th.breakpoints.down('md')]: {
      marginTop: '0',
    },
  },
  title: {
    textTransform: 'uppercase',
  },
  socialItem: {
    margin: '1vh',
  },
}));

export function Contact() {
  const styles = useStyles();
  return (
      <Paper>
    <Grid container direction="column" className={styles.container} id="contact" alignItems="center" alignContent="center">
      <Grid item>
        <Typography variant="h4" color="textPrimary">
          Kasia Kilar treningi
        </Typography>
        {/*<Typography variant="h6" align="center">*/}
        {/*  <a style={{ color: 'inherit', textDecoration: 'inherit' }} href="tel:+48795147926">+48 795 147 926</a>*/}
        {/*</Typography>*/}
      </Grid>
      <Grid item>
        <Grid item>
          <SocialIcon url="https://www.facebook.com/kasiakilartreningi" className={styles.socialItem} />
          <SocialIcon url="https://www.instagram.com/kasia_kilar_treningi" className={styles.socialItem} />
          <SocialIcon url="https://www.youtube.com/channel/UCEUhvrm6Ayxg6qxOUaUtY5w" className={styles.socialItem} />
          <SocialIcon url="https://www.tiktok.com/@kkilarek" className={styles.socialItem} />
          <SocialIcon url="mailto:kasiakilartreningi@gmail.com" className={styles.socialItem} />
        </Grid>
      </Grid>
    </Grid>
      </Paper>
  );
}
